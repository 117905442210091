import * as React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { SocialIcon } from "react-social-icons"

import Metadata from "../components/metadata"
import Layout from "../components/layout"
import Heading2 from "../components/heading2"
import NewsList from "../components/news-list"
import { MEDIA_MOBILE } from "../const"

import { HomeQuery } from "../../types/graphql-types"

type Props = {
  data: HomeQuery
}

type CollageImageKey =
  | "colla0p"
  | "colla0l"
  | "colla1p"
  | "colla1l"
  | "colla2p"
  | "colla2l"
  | "colla3p"
  | "colla3l"
  | "colla4p"
  | "colla4l"

const Home: React.FC<Props> = ({ data }) => {
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isMobile = useMediaQuery({ query: `(${MEDIA_MOBILE})` })

  return (
    <Layout>
      <Metadata />
      <HeroSection>
        <figure>
          {[...Array(5).keys()].map(i => (
            <Img
              key={i}
              fluid={
                data[
                  `colla${i}${
                    isPortrait && isMobile ? "p" : "l"
                  }` as CollageImageKey
                ].childImageSharp.fluid
              }
              style={{
                opacity: 1 - i * 0.15,
              }}
              alt=""
            />
          ))}
        </figure>
      </HeroSection>
      <NewsSection>
        <Heading2 feedUrl="/rss-news.xml">News</Heading2>
        <NewsList edges={data.news.edges} />
        <NewsMore>
          <Link to="/news">&gt;&gt;もっと見る</Link>
        </NewsMore>
      </NewsSection>
      <SocialIcons>
        <li>
          <SocialIcon url="https://twitter.com/upgrade_ayp" target="_blank" />
        </li>
        <li>
          <SocialIcon
            url="https://www.youtube.com/channel/UCU_vZ0kggiHFOxnvHqHt_aQ"
            target="_blank"
          />
        </li>
      </SocialIcons>
      <YouTube>
        <YouTubeInner>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/jhi5TWSvNlo"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="ayapi「役に立つ情報 (Helpful Information)」Trailer Video"
          ></iframe>
        </YouTubeInner>
      </YouTube>
      <YouTube>
        <YouTubeInner>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/OjFm2UGVgeA"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="【本人】ぁゃぴ（階段ライト）ムーンライト多摩川 歌ってみた"
          ></iframe>
        </YouTubeInner>
      </YouTube>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query Home {
    colla0p: file(relativePath: { eq: "DSC00313-colla/0.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 90, rotate: 270) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla0l: file(relativePath: { eq: "DSC00313-colla/0.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla1p: file(relativePath: { eq: "DSC00313-colla/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 90, rotate: 270) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla1l: file(relativePath: { eq: "DSC00313-colla/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla2p: file(relativePath: { eq: "DSC00313-colla/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 90, rotate: 270) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla2l: file(relativePath: { eq: "DSC00313-colla/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla3p: file(relativePath: { eq: "DSC00313-colla/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 80, rotate: 270) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla3l: file(relativePath: { eq: "DSC00313-colla/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla4p: file(relativePath: { eq: "DSC00313-colla/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 80, rotate: 270) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    colla4l: file(relativePath: { eq: "DSC00313-colla/4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 270, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    news: allContentfulNewsPost(
      sort: { fields: updateDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          ...NewsListItem
        }
      }
    }
  }
`
const HeroSection = styled.section`
  > figure {
    display: flex;
    margin: 0 auto;

    max-width: 1350px;
    min-height: 50vh;
    max-height: calc(100vh - 60px);

    flex-direction: row;

    @media (${MEDIA_MOBILE}) and (orientation: portrait) {
      flex-direction: column;
    }

    > * {
      flex-grow: 1;
    }
  }
`
const NewsSection = styled.section`
  box-sizing: border-box;
  padding: 0 20px;
  max-width: 748px;
  margin: 2rem auto;

  @media (${MEDIA_MOBILE}) {
    width: 100%;
  }
`
const NewsMore = styled.div`
  text-align: right;
  padding: 20px 0;
`
const SocialIcons = styled.ul`
  margin: 40px auto;
  text-align: center;

  > li {
    display: inline-block;
    padding: 0 10px;
  }
`
const Twitter = styled.div`
  margin: 40px auto;
  max-width: 550px;
`
const YouTube = styled.div`
  margin: 40px auto;
  max-width: 550px;
`
const YouTubeInner = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
